// start_ai_generated
import React, { useState } from 'react'
import { Button, Text } from '@components/ui'

function LogisticsInput({ onConfirm, shoppingInfo, initAttributes, loading }) {
  const hes_appointment_method = shoppingInfo?.hes_appointment_method
  const [selectedLogistics, setSelectedLogistics] = useState(
    initAttributes?.value || hes_appointment_method?.values[0].value
  )

  const handleSelectChange = (event) => {
    setSelectedLogistics(event.target.value)
    onConfirm(event.target.value)
  }

  return (
    <div className="px-9 md:px-5">
      <Text
        variant="paragraph"
        html={hes_appointment_method?.title}
        className="text-base font-semibold mb-2"
      />
      <div className="flex items-center justify-between">
        <select
          className="w-full flex-1 border-b-2 border-black bg-transparent py-1"
          value={selectedLogistics}
          onChange={handleSelectChange}
        >
          {hes_appointment_method?.values?.map((item, i) => {
            return (
              <option value={item.value} key={item.value + i}>
                {item.label}
              </option>
            )
          })}
        </select>
      </div>
    </div>
  )
}

export default LogisticsInput

// end_ai_generated
