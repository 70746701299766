import { Text } from '@components/ui'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import { useRouter } from 'next/router'
import { useRelativeLink } from '@commerce/product/use-relative-link'

const DiscountDescription = ({ data }) => {
  const { setRelativeLink } = useRelativeLink()
  const { locale } = useRouter()
  return (
    <div className="flex items-center justify-between pt-2 text-xs md:text-[3vw]">
      <div className="flex items-center">
        {data?.tag && (
          <Text
            html={data?.tag}
            className="mr-1 flex-shrink-0 rounded border border-anker-color px-1 text-anker-color"
            variant="paragraph"
          />
        )}
        <Text
          html={data?.description}
          className="h-fit font-light"
          variant="paragraph"
        />
      </div>
      <a
        className="font-light text-anker-color"
        href={setRelativeLink({ link: data?.link?.to })}
        onClick={() =>
          pageGTMEvent({
            event: 'uaEvent',
            eventCategory: 'Cart Pop Up',
            eventAction: 'special_offer',
            eventLabel: 'learn_more',
          })
        }
      >
        {data?.link?.text}
      </a>
    </div>
  )
}

export default DiscountDescription
