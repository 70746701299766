import { useCart } from 'lib/shopify/api/cart'
import { useMemo } from 'react'

const useBundleItem = () => {
  const { data } = useCart()

  return useMemo(() => {
    const hasBundle = []
    const actionItem = []
    data?.lineItems?.forEach((item) => {
      const sku = item?.variant?.sku
      const index = hasBundle.indexOf(sku)
      if (sku && index > -1) {
        hasBundle.push('bundle-' + sku)
        actionItem.push(data?.lineItems[index])
      } else {
        hasBundle.push(sku)
        actionItem.push(null)
      }
    })
    return actionItem
  }, [data?.lineItems])
}

export default useBundleItem
