import useCustomer from '@shopify/customer/use-customer'
import { useUI } from '@components/ui'
import { updateRef } from 'templates/Pages/AnkerDay/helper'
import { useRouter } from 'next/router'

const CartBanner = ({ metafields }) => {
  const { ankerDayCopy = {}, copywriting } = metafields
  const data = ankerDayCopy?.cart
  const { data: customer } = useCustomer()
  const { setLoginPop, setRegistrationsPopup, setRegistrationsModelStatus } =
    useUI()
  const { locale } = useRouter()

  if (!data) {
    return null
  }

  return (
    <div className="bg-[#ffecc8] px-4 py-2 text-center text-[15px] [&_span]:text-anker-color">
      {customer?.email ? (
        <a
          href={`/${locale}${data?.to}?ref=${data?.ref}`}
          dangerouslySetInnerHTML={{ __html: data?.titleAfter }}
          target="_blank"
          rel="noreferrer"
        ></a>
      ) : (
        <button
          onClick={() => {
            // setLoginPop(true)
            if (copywriting?.oldLogin) {
              setLoginPop(true)
            } else {
              setRegistrationsPopup(true)
              setRegistrationsModelStatus('quickLogin')
            }
            updateRef(data.ref)
          }}
          dangerouslySetInnerHTML={{ __html: data?.titleBefore }}
        ></button>
      )}
    </div>
  )
}

export default CartBanner
