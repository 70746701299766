import fetcher from '@lib/fetcher'
import { I18N_STORE_DOMAIN } from '@shopify/const'

const getProductPersonalizeBySkus = async ({
  locale,
  skus,
  clientId,
  formatData,
  limit, //条数限制
  plan, // a b test
  recom_type = '',
}) => {
  if (!skus || skus.length === 0) return {}
  const url = `/api/multipass/rainbowbridge/personalize/relation`
  const result = await fetcher({
    url,
    locale,
    method: 'GET',
    repeatKey: 'skus',
    body: {
      skus: skus,
      shopify_domain: I18N_STORE_DOMAIN[locale],
      user_id: clientId || '',
      limit,
      plan,
      recom_type,
    },
  })
  if (!result?.errors && result?.data) {
    const resultData = result?.data
    if (formatData) {
      return formatData(resultData)
    } else {
      return resultData
    }
  } else {
    return false
  }
}

export default getProductPersonalizeBySkus
