import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'
import { Button, Text } from '@components/ui'
import { motion } from 'framer-motion'
import { XMarkIcon } from '@heroicons/react/24/outline'
import Spin from '@components/icons/common/Spin'

import cn from 'classnames'

const CodeInput = ({
  s,
  data,
  shoppingInfo,
  onSubmit,
  codeError,
  setCodeError,
  codeApplying,
  codeRemoving,
  handleCodeRemove,
  setCodeDeleteModal,
  discountCodes,
  codeTips,
}) => {
  const [expand, setExpand] = useState(false) // 输入框是否展开
  const [code, setCode] = useState('')
  const showCodeTips =
    discountCodes.length > 0 &&
    discountCodes.some((item) => item?.applicable === true)

  return (
    <div className="bg-white py-4">
      <button
        tag="Auto-10000461"
        className="flex w-full items-center justify-between"
        onClick={() => setExpand(!expand)}
      >
        <span className="flex-none text-base">{shoppingInfo?.enterCode}</span>
        <div className="flex gap-1">
          <span className="ml-2 flex-grow text-base text-[#0082FF] ">
            {shoppingInfo?.optional}
          </span>
          <ChevronDownIcon
            fill="#0082FF"
            className={cn(
              'mt-[1px] h-5 w-5 flex-none transition-all duration-200',
              {
                ['rotate-[180deg]']: expand,
              }
            )}
          />
        </div>
      </button>
      <div
        className={cn(
          'mt-0 h-0 origin-top scale-y-0 overflow-hidden transition-all duration-200',
          {
            ['mt-2 !h-auto !scale-y-100']: expand,
          }
        )}
      >
        <p className={cn('mb-3 !hidden text-gray-500')}>
          {shoppingInfo?.enterCodeInfo}
        </p>
        <form
          className={cn(
            'group flex border border-[#D8D8D8] pr-4 transition-all hover:border-[#0082FF]',
            {
              'border-[#ff2b2b]': !!codeError,
            }
          )}
          onSubmit={async (e) => {
            e.preventDefault()
            if (!code.trim()) return
            const result = await onSubmit(code.trim())
            if (result === 'success') {
              setCode('')
              setCodeError('')
            }
          }}
        >
          <input
            className="h-[42px] flex-grow text-base bg-white px-3 outline-none"
            tag="Auto-10000462"
            placeholder={shoppingInfo?.placeholder || 'Enter Your Promo Code'}
            type="text"
            value={code}
            onChange={(e) => {
              setCode(e.target.value)
              setCodeError('')
            }}
          />
          <Button
            variant="slim"
            type="submit"
            className="ml-3 flex-none font-semibold text-[#D8D8D8] transition-all group-hover:text-[#0082FF]"
            tag="Auto-10000463"
            loading={codeApplying}
          >
            {shoppingInfo?.apply}
          </Button>
        </form>
        {codeError && (
          <Text
            variant="paragraph"
            html={codeError}
            className="margin-0 pt-[10px] text-left text-[13px] leading-tight text-[#ee3749]"
          />
        )}
        {data?.discountCodes?.length > 0 && (
          <motion.div
            className=" overflow-hidden"
            animate={'show'}
            variants={{
              hidden: {
                height: 0,
              },
              show: {
                height: 'auto',
              },
            }}
          >
            <div className="mt-2 flex w-full flex-wrap gap-[6px]">
              {shoppingInfo?.coupon && <span>{shoppingInfo?.coupon}</span>}

              {data?.discountCodes?.map((item, i) => {
                if (!item?.applicable) return ''
                return (
                  <div
                    className="flex justify-between "
                    key={`discountCodes-${item?.code}-${i}`}
                  >
                    <div
                      className={cn(
                        s.codeIconBox,
                        s.codeIconBoxBg,
                        'relative overflow-hidden'
                      )}
                    >
                      {codeRemoving && (
                        <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-slate-600 opacity-70">
                          <Spin className="h-4 w-4" />
                        </div>
                      )}
                      <Text
                        variant="inline"
                        html={item.code}
                        tag="Auto-10000464"
                        className="mt-[2px] align-middle"
                      />

                      <button
                        aria-label="remove code"
                        variant="plain"
                        tag="Auto-10000465"
                        className="ml-1 flex h-4 w-4 items-center justify-center rounded-full bg-[#a3a3a5]"
                        onClick={() => setCodeDeleteModal(item.code)}
                        disabled={codeRemoving}
                        loading={codeRemoving.toString()}
                      >
                        <XMarkIcon className="inline-block h-3 w-3"></XMarkIcon>
                      </button>
                    </div>
                  </div>
                )
              })}
            </div>
          </motion.div>
        )}
      </div>

      {codeTips && showCodeTips && (
        <Text
          variant="paragraph"
          html={codeTips}
          className="pt-[10px] text-left text-[13px] leading-tight text-[#ee3749]"
        />
      )}
    </div>
  )
}

export default CodeInput
