/* eslint-disable react-hooks/rules-of-hooks */
import { cn } from '@components/helper'
import {
  klarnaPlacement,
  OnSiteMessagingValue,
  PlacementValue,
  useKlarnaOnSiteMessaging,
  useKlarnaPlacementImpression,
  useKlarnaPlacementRefresh,
} from '@frontend-sdk/klarna'

interface Props {
  className?: string
  price: number
  settings: {
    clientId: string
    locale:
      | 'en-US'
      | 'es-US'
      | 'de-AT'
      | 'en-AT'
      | 'de-DE'
      | 'en-DE'
      | 'da-DK'
      | 'en-DK'
      | 'en-FI'
      | 'fi-FI'
      | 'sv-FI'
      | 'en-GB'
      | 'en-NO'
      | 'no-NO'
      | 'en-SE'
      | 'sv-SE'
      | 'en-NL'
      | 'nl-NL'
    region: 'na' | 'eu'
  }
}

const Klarna = (props: Props) => {
  const { price, settings, className } = props

  if (!settings) {
    return null
  }
  const { clientId, region, locale } = settings
  if (!clientId || !region || !locale) {
    return null
  }
  const onSiteMessagingValue: OnSiteMessagingValue = {
    clientId,
    region,
    env: 'prod', // klarna 目前没有测试环境
  }
  const placementValue: PlacementValue = {
    key: 'credit-promotion-badge',
    locale,
    preloaded: true,
    // 价格乘 100 原因：https://docs.klarna.com/on-site-messaging/faq/#what-are-placement-tags?
    purchaseAmount: Math.ceil(price * 100).toString(),
  }

  useKlarnaOnSiteMessaging(onSiteMessagingValue)
  useKlarnaPlacementImpression(placementValue)
  useKlarnaPlacementRefresh()

  return (
    <div className={cn('klarna', className)}>
      {klarnaPlacement(placementValue)}
    </div>
  )
}

export default Klarna
