import { pageGTMEvent, admitadRetag } from '@lib/utils/thirdparty'
import { atobID, md5Func, sha256 } from '@lib/utils/tools'
import { BRAND } from '@shopify/const'

export const beginCheckout = ({ data, group, position }) => {
  if (!data?.lineItems.length) return
  pageGTMEvent({
    event: 'ga4Event',
    event_name: 'begin_checkout',
    event_parameters: {
      page_group: group,
      position: position || 'Cart Pop Up_Checkout',
      currency: data?.currency.code,
      value: data?.totalPrice,
      items: data?.lineItems.map((product) => {
        const variant = product?.variant || {}
        return {
          item_id: variant?.sku,
          item_name: product?.name,
          item_variant: variant?.name,
          price: variant?.price,
          quantity: product?.quantity || 1,
        }
      }),
    },
  })

  pageGTMEvent({
    event: 'eeEvent',
    eventCategory: 'EnhancedEcommerce',
    eventAction: 'BuyNow',
    eventLabel: data?.lineItems[0]?.variant?.sku,
    ecommerce: {
      currencyCode: data?.currency.code,
      add: {
        products: data?.lineItems.map((product) => {
          const variant = product?.variant || {}
          return {
            name: product?.name,
            id: variant?.sku,
            category: '',
            categoryName: '',
            brand: BRAND,
            variant: variant?.name,
            price: variant?.price,
            quantity: product?.quantity || 1,
          }
        }),
      },
    },
  })
}

export const criteoTrace = ({
  data,
  criteoSettings,
  locale,
  customer,
  thirdparty,
}) => {
  return false // criteo 事件移动到GTM
  const criteoList = data?.lineItems?.map((item) => ({
    id: atobID(item.variant?.id),
    price: item.variant?.price,
    quantity: item.quantity,
  }))
  window.criteo_q = window.criteo_q || []
  var deviceType = /iPad/.test(navigator.userAgent)
    ? 't'
    : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
        navigator.userAgent
      )
    ? 'm'
    : 'd'
  window.criteo_q.push(
    {
      event: 'setAccount',
      account: {
        an: criteoSettings?.an,
        cn: criteoSettings?.cn,
        ln: criteoSettings?.ln,
      },
    },
    {
      event: 'setEmail',
      email: customer?.email ? md5Func(customer?.email) : '',
    },
    { event: 'setSiteType', type: deviceType },
    {
      event: 'viewBasket',
      item: criteoList,
    }
  )
  if (thirdparty?.admitad_code) {
    window.ad_products = data?.lineItems?.map((item) => ({
      id: atobID(item.productId),
      number: item.quantity,
    }))
    admitadRetag({ code: '9ce8884c12', level: 3 })
  }
}

export const viewItems = ({ items, skus }) => {
  pageGTMEvent({
    event: 'ga4Event',
    event_name: 'view_item_list',
    event_parameters: {
      page_group: 'Cart Pop Up_' + skus, //取侧边栏购物车内的原本的所有SKU
      item_list_name: 'Cart Pop Up_Bundle',
      items,
    },
  })
}
