/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback, useMemo } from 'react'
import cn from 'classnames'
import Modal from 'react-modal'
import Decimal from 'decimal.js'
import { useRouter } from 'next/router'
import {
  TagIcon,
  ChevronDownIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/20/solid'
import { useCommerce } from '@commerce/index'
import InstalmentsModal from '@components/product/InstalmentsModal'
import Affirm from '@components/product/Affirm'
import Paypal from '@components/product/Paypal'
import Klarna from '@components/product/Klarna'

import { createCheckout } from 'lib/shopify/api/checkout'
import { useCart, useCartAttributesUpdate } from 'lib/shopify/api/cart'
import usePrice, { formatPrice } from '@shopify/product/use-price'
import useCustomer from '@shopify/customer/use-customer'
import {
  useCodeApply,
  useRemoveCode,
  useUpdateItem,
} from 'lib/shopify/api/cart'
import { Button, Text, Picture } from '@components/ui'
import { useUI } from '@components/ui/context'
import { Cross } from '@components/icons/next'
import { motion } from 'framer-motion'
import SidebarLayout from '@components/common/SidebarLayout'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import { BRAND } from '@shopify/const'
import { beginCheckout, criteoTrace } from '../trace'
import { ProductIconBar, ShoppingCartGifts } from '@components/product'
import { getGtmGroup } from '@components/helper'
import { Close } from '@components/icons/next'
import CartBanner from '@products/components/MemberDiscount/CartBanner'
import { useRelativeLink } from '@commerce/product/use-relative-link'
import useAllDiscountedProducts from '@lib/hooks/useAllDiscountedProducts'
import Slider from 'react-slick'

import Recommends from '../Recommends'
import CartItem from '../CartItem'
import useRecommendsProducts from '../useRecommendsProducts'
import useGradientGiftsCampaign from '../useGradientGiftsCampaign'
import useBreakpointDiscount from '../useBreakpointDiscount'
import CodeInput from '../CodeInput'
import LogisticsInput from '../LogisticsInput'
import DiscountDescription from '../DiscountDescription'
import useBundleItem from '../useBundleItem'
import CampainModal from './CampainModal'

import s from './CartSidebarView.module.css'
import Cookies from 'js-cookie'
import debounce from 'lodash.debounce'

const CartSidebarView = ({ metafields, pageType }) => {
  const error = null
  const success = null
  const {
    shoppingInfo,
    infos = {},
    cartRecommends,
    quantityBreakDiscount: quantityBreakDiscountData,
    criteoSettings,
    shopCommon,
    thirdparty,
    summerCampaignDiscount,
    gradient_gifts,
    fridayBlackChg2023,
    preRender,
    shopPreRender,
    ankerDayPPSDiscount,
    copywriting,
  } = metafields
  const { solixFanCart } = copywriting || {}
  const { setRelativeLink } = useRelativeLink()
  const { shop } = useCommerce()
  const allDiscount = useAllDiscountedProducts()
  const { data: customer } = useCustomer()
  const { locale, query, asPath } = useRouter()
  const { closeSidebar, setSidebarView, displaySidebar: show } = useUI()
  const { cartCookie } = useCommerce()
  const { data, isLoading, isEmpty } = useCart()
  const codeApply = useCodeApply()
  const removeCode = useRemoveCode()
  const cartAttributesUpdate = useCartAttributesUpdate()

  const [tipError, setTipError] = useState(null)
  const [codeError, setCodeError] = useState(null)
  const [recommendsAddedSku, setRecommendsAddedSkuAdded] = useState() // 推荐商品中加入购物车
  const [codeApplying, setCodeApplying] = useState(false)
  const [codeRemoving, setCodeRemoving] = useState(false)
  const [loadingTocheckout, setLoading] = useState(false)
  const [showFixed, setShowFixed] = useState(false)
  const [codeDeleteModal, setCodeDModal] = useState('')
  const updateItem = useUpdateItem()

  const setCodeDeleteModal = useCallback((v) => {
    setCodeDModal(v)
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'lp_button',
      plus_member_status: '',
      event_parameters: {
        page_group: 'Activity Page_' + document.location.href,
        position: 'codeDelete',
        button_name: 'X',
      },
    })
  }, [])

  const hasSolixFanPro = useMemo(() => {
    return data?.lineItems?.find((item) => {
      return item?.product?.tags?.includes(solixFanCart?.tag)
    })
  }, [data?.lineItems, customer])

  const appliedCode = useMemo(() => {
    const apply = data?.discountCodes
      ?.filter((item) => item?.applicable)
      ?.map((item) => item?.code)
    return apply?.length > 0 ? apply : ['']
  }, [data?.discountCodes])

  const [toCheckoutUrl, queryString] = useMemo(() => {
    const params = {
      // discount: appliedCode.join(','),
    }
    const queryString = Object.keys(params)
      .map((key) => {
        return !!params[key]
          ? encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
          : ''
      })
      .join('&')

    return [data?.url ? data.url + '?' + queryString : '', queryString]
  }, [data])

  const goToCheckout = useCallback(
    async (data, appliedCode) => {
      setLoading(true)
      let targetUrl = toCheckoutUrl
      try {
        // 兼容方案：在升级 checkout.liquid 前，无法从cart带多个discount到checkout
        if (data?.lineItems?.length > 0 && appliedCode?.length > 1) {
          const checkout = await createCheckout({
            locale,
            lineItems: data?.lineItems?.map((item) => ({
              variantId: item?.variant?.id,
              quantity: item?.quantity,
              ...(item?.customAttributes && {
                customAttributes: item?.customAttributes,
              }),
            })),
            customAttributes: data?.customAttributes,
            discountCodes: appliedCode,
          })

          targetUrl = checkout?.webUrl + '&' + queryString
          localStorage.setItem('checkoutwebid', checkout?.id)
        }
      } catch (e) {
        console.log(e)
      }
      window.location.assign(targetUrl)
      setLoading(false)
    },
    [toCheckoutUrl, queryString]
  )

  const appliedOrderCode = useMemo(() => {
    const codes = data?.discountAllocations?.reduce((arr, cur) => {
      if (arr.every((item) => item?.code !== cur?.code)) arr.push(cur)
      return [...arr]
    }, [])
    const result = codes?.map((item) => {
      const amountTotal = data?.discountAllocations?.reduce((pre, cur) => {
        if (cur?.code === item?.code) return Number(pre) + Number(cur.amount)
        return Number(pre)
      }, 0)

      return {
        amount: amountTotal,
        amountFormat: formatPrice({
          amount: amountTotal * -1,
          currencyCode: data?.currency?.code,
          locale,
        }),
        code: item.code,
      }
    })
    return result
  }, [data?.discountAllocations])

  const { personalizeProducts, recommendsProducts } = useRecommendsProducts({
    recommendsAddedSku,
    cartRecommends,
    shoppingInfo,
    show,
    pageType,
    recom_type: 'shopping',
  })

  const has_appointment_tags = useMemo(() => {
    if (!shoppingInfo?.hes_appointment_method) return []
    if (shoppingInfo?.hes_appointment_method?.skus?.length > 0) {
      return data?.lineItems?.filter((item) =>
        shoppingInfo?.hes_appointment_method?.skus?.includes(item?.variant?.sku)
      )
    }
    return (
      data?.lineItems?.filter((item) =>
        item?.product?.tags?.some((t) =>
          shoppingInfo?.hes_appointment_method?.tags?.includes(t)
        )
      ) || []
    )
  }, [data?.lineItems])

  useEffect(() => {
    let error = false
    const has_soldout = data?.lineItems?.some(
      (item) => !item?.variant?.availableForSale
    )
    if (has_soldout) error = 'Unable to submit order, product is out of stock.'
    setTipError(error)
  }, [data?.lineItems])

  useEffect(() => {
    if (!shoppingInfo?.hes_appointment_method) return
    const has_appointment_method = data?.customAttributes?.find(
      (attribute) =>
        attribute.key === shoppingInfo?.hes_appointment_method?.attribute_key
    )

    if (!has_appointment_method && has_appointment_tags?.length > 0) {
      cartAttributesUpdate({
        customAttributes: [
          {
            key: shoppingInfo?.hes_appointment_method?.attribute_key,
            value: shoppingInfo?.hes_appointment_method?.values[0]?.value,
          },
        ],
      })
    }
    if (has_appointment_method && has_appointment_tags?.length === 0) {
      cartAttributesUpdate({
        customAttributes: data?.customAttributes?.filter(
          (attribute) =>
            attribute.key !==
            shoppingInfo?.hes_appointment_method?.attribute_key
        ),
      })
    }
  }, [shoppingInfo?.hes_appointment_method, data?.lineItems])

  useEffect(() => {
    if (
      customer &&
      !data?.customAttributes?.find(
        (attribute) =>
          attribute.key === '_login_user' && attribute.value === '1'
      )
    ) {
      const checkoutIdCookie = cartCookie[locale]
      const cartId = Cookies.get(checkoutIdCookie)
      const r = cartAttributesUpdate({
        customAttributes: [{ key: '_login_user', value: '1' }],
      })
    }
  }, [data?.lineItems?.length, customer])

  const applyCode = async (code, only) => {
    setCodeApplying(true)
    setCodeError(null)
    try {
      await codeApply({ discountCode: code, only: !!only })
      setCodeApplying(false)
      return 'success'
    } catch (error) {
      setCodeApplying(false)
      setCodeError(error.message)
    }
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'lp_button',
      plus_member_status: '',
      event_parameters: {
        page_group: 'Activity Page_' + document.location.href,
        position: 'applyCode',
        button_name: 'Apply',
      },
    })
  }

  const sortBySkuAndRelated = (arr) => {
    if (arr?.length < 2) return arr
    const has_related_handlesku = arr?.find(
      (item) =>
        item?.customAttributes?.some(
          (att) => att?.key === '_freegift_related_handlesku'
        ) && Number(item?.totalAmount) === 0
    )
    if (!has_related_handlesku) return arr
    const map = {}
    let start = arr.find((item) =>
      item?.customAttributes?.every(
        (att) => att?.key !== '_freegift_related_handlesku'
      )
    )

    const result = []
    start.freegift_related = []
    while (start && result?.length < arr?.length) {
      arr.forEach((item) => {
        const related = item?.customAttributes?.find(
          (att) => att?.key === '_freegift_related_handlesku'
        )?.value
        if (
          related === `${start?.handle},${start?.variant?.sku}` &&
          Number(item?.totalAmount) === 0
        ) {
          start.freegift_related?.push(item)
        }
      })
      result.push({ ...start })
      start = arr.find((item) => {
        return (
          result?.every((r) => r?.variant?.sku !== item?.variant?.sku) &&
          Number(item?.totalAmount) !== 0 &&
          !item?.customAttributes?.some(
            (att) => att?.key === '_freegift_related_handlesku'
          )
        )
      })
      if (start) start.freegift_related = []
    }
    return result
  }

  const codeRemove = async (successCbk, code) => {
    // console.log('code remove:')
    try {
      setCodeRemoving(true)
      await removeCode({ discountCode: code })
      successCbk && successCbk()
      setCodeRemoving(false)
    } catch (error) {
      setCodeRemoving(false)
    }
  }

  const { price: totalTaxAmount } = usePrice(
    data?.totalTaxAmount && {
      amount: Number(data?.totalTaxAmount || 0),
      currencyCode: data.currency.code,
    }
  )

  const hasDiscount = useMemo(
    () => data?.discountCodes?.some((code) => code.applicable),
    [data?.discountCodes]
  )

  const {
    involvedLines: gradientGiftsLines,
    reorder,
    summerLevel: gradientLevel,
    nextSummerLevel: nextLevel,
    involvedSubTotal: nextInvolvedSubTotal,
  } = useGradientGiftsCampaign({
    campaign: gradient_gifts,
    _giveaway: '_giveaway_gradient_gifts',
  })

  const {
    involvedLines: summerCampaignLines,
    reorder: summerReorder,
    summerLevel,
    nextSummerLevel,
    involvedSubTotal,
  } = useGradientGiftsCampaign({
    campaign: summerCampaignDiscount,
    _giveaway: '_giveaway_summerCampaignDiscount',
  })

  const {
    showLearnMore,
    quantityBreakCode,
    setQuantityBreakCodeRemoved,
    isQuantityBreakCode,
    isNormalCode,
  } = useBreakpointDiscount({
    quantityBreakDiscountData,
    disabled: gradientGiftsLines?.length > 0 || summerCampaignLines?.length > 0,
    hasDiscount,
    applyCode,
    codeRemove,
    setCodeError,
    appliedCode: appliedCode[0],
  })

  useEffect(() => {
    if (show) {
      criteoTrace({ data, locale, criteoSettings, customer, thirdparty })
    }
  }, [show, data?.lineItems, locale])

  const handleCodeRemove = (code) =>
    codeRemove(() => {
      // console.log('quantityBreakCode:', quantityBreakCode)
      // 如果去除了所有的普通 code，并且有符合满减条件，默认应用上满减
      const isLast = appliedCode?.length <= 1
      if (isNormalCode(code) && quantityBreakCode && isLast) {
        applyCode(quantityBreakCode)
      }
      if (isQuantityBreakCode(code)) {
        setQuantityBreakCodeRemoved(true)
      }
    }, code)

  const { price: subTotal } = usePrice(
    data && {
      amount: Number(data.lineItemsSubtotalPrice),
      currencyCode: data.currency.code,
    }
  )

  const { price: totallineItemsDiscount } = usePrice(
    data && {
      amount: Number(data.totallineItemsDiscount) * -1,
      currencyCode: data.currency.code,
    }
  )

  const { price: total } = usePrice(
    data && {
      amount: Number(data.totalPrice),
      currencyCode: data.currency.code,
    }
  )

  const bundleItem = useBundleItem()

  const handleClose = () => {
    closeSidebar()
    pageGTMEvent({
      event: 'close_side_cart',
      page_group: document.location.href,
    })
  }

  const getBundleSize = (name) => {
    const bundle_size = data?.lineItems?.filter((item) => {
      return item?.customAttributes?.some((obj) => obj.value === name)
    })
    return bundle_size ? bundle_size?.length : 1
  }

  const productMix = useMemo(() => {
    if (!fridayBlackChg2023) return
    // 判断是否有小充商品
    let chgPro
    let ppsPro
    data?.lineItems?.map((item) => {
      if (!chgPro) {
        chgPro = item?.product?.tags?.find((tag) =>
          fridayBlackChg2023?.chg_tags?.includes(tag)
        )
      }
      if (!ppsPro) {
        ppsPro = item?.product?.tags?.find((tag) =>
          fridayBlackChg2023?.pps_tags?.includes(tag)
        )
      }
    })
    if (chgPro && ppsPro) return true
    return false
  }, [data])

  const minimum = useMemo(() => {
    if (
      !fridayBlackChg2023 ||
      !productMix ||
      !fridayBlackChg2023?.minimum?.spend
    )
      return ''
    const chgPro = data?.lineItems?.reduce((prev, item) => {
      const in_spend = item?.product?.tags?.some((tag) =>
        fridayBlackChg2023?.chg_tags?.includes(tag)
      )
      if (in_spend) {
        return new Decimal(prev).plus(new Decimal(item?.totalAmount))
      }
      return prev
    }, 0)

    const sub = new Decimal(new Decimal(fridayBlackChg2023?.minimum?.spend))
      .minus(chgPro)
      .toNumber()

    const spend =
      sub > 0
        ? formatPrice({
            amount: sub,
            currencyCode: data?.currency?.code,
            locale,
          })
        : ''
    return spend
  }, [data])

  const codeColseTips = useMemo(() => {
    if (!fridayBlackChg2023) return
    // 筛选出小充产品
    const chgItem = data?.lineItems?.filter((item) => {
      const tags = item?.product?.tags || []
      return fridayBlackChg2023?.chg_tags.some((tag) => tags.includes(tag))
    })

    // 小充产品当前总价
    let curTotal = 0
    // 小充产品黑五活动总价
    let dealsTotal = 0

    let dealsSubtotal = 0
    chgItem?.map((item) => {
      dealsSubtotal += Number(item?.subtotalAmount)
      curTotal += Number(item?.totalAmount)
    })
    const chgQuantity = chgItem?.reduce((q, i) => q + i.quantity, 0) || 0
    const discountKeys = Object.keys(fridayBlackChg2023?.discount)
    const lastKey =
      discountKeys.length > 0 ? discountKeys[discountKeys.length - 1] : 0
    const number = Math.min(chgQuantity, lastKey)
    const discountText = fridayBlackChg2023?.discount[number] || 0.25
    const text = fridayBlackChg2023?.code_tips
      .replace('$value', discountText * 100 + '%')
      .replace('$quantity', number)
    // 换算到分 再进行折扣计算
    const sale_price =
      (dealsSubtotal * 100 - dealsSubtotal * 100 * discountText) / 100
    // 总分价向上取整后再保留两位小数
    dealsTotal = Math.ceil(sale_price * 100) / 100
    // console.log(number, discountText, sale_price, dealsTotal, 'dealsTotal')
    if (chgQuantity > 1 && curTotal > dealsTotal) return text
    return false
  }, [data])

  const handleUpdate = useCallback(
    debounce(() => {
      if (
        customer &&
        data?.lineItems?.length > 0 &&
        ankerDayPPSDiscount &&
        ankerDayPPSDiscount?.customAttribute === '_anker_day'
      ) {
        const allSku =
          ankerDayPPSDiscount?.products?.flat()?.map((item) => item?.sku) || []
        const dataLineItems = []
        data?.lineItems?.forEach((i) => {
          if (
            i?.customAttributes?.indexOf('_anker_day') === -1 &&
            allSku?.includes(i?.variant?.sku)
          ) {
            dataLineItems?.push({
              ...i,
              customAttributes: [
                {
                  key: '_anker_day',
                  value: '_anker_day',
                },
              ],
            })
          }
        })
        if (dataLineItems?.length > 0) {
          updateItem(dataLineItems)
        }
      }
    }, 600), // 300ms 的防抖时间，可以根据需要调整
    [customer, data?.lineItems, ankerDayPPSDiscount, updateItem]
  )

  useEffect(() => {
    handleUpdate()
  }, [data?.lineItems?.length, customer])

  if (!shoppingInfo) return null
  return (
    <SidebarLayout
      className={cn(s.root, {
        [s.empty]: error || success || isLoading || isEmpty,
      })}
      handleClose={handleClose}
      headerTitle={
        isEmpty
          ? `<b>${shoppingInfo?.myCart || 'My Cart'}</b> (0)`
          : `<b>${
              shoppingInfo?.myCart || 'My Cart'
            }</b> (${data?.lineItems?.reduce(
              (acc, item) => acc + (item?.quantity || 0),
              0
            )})`
      }
      metafields={metafields}
    >
      {tipError && (
        <div className="top-18 absolute left-1/2 z-50 w-[85%] -translate-x-1/2">
          <div className="flex items-center justify-start gap-2 rounded-md bg-white px-2 py-3 shadow-md">
            <Text className="text-xs " html={tipError} />
          </div>
        </div>
      )}

      {isLoading ? (
        <EmptyBox
          shoppingInfo={shoppingInfo}
          locale={locale}
          setRelativeLink={setRelativeLink}
        />
      ) : error ? (
        <div className="flex flex-1 flex-col items-center justify-center px-4">
          <span className="flex h-16 w-16 items-center justify-center rounded-full border border-white">
            <Cross width={24} height={24} />
          </span>
          <h2 className="pt-6 text-center text-xl font-light">
            {shoppingInfo?.errorCart}
          </h2>
        </div>
      ) : (
        <div className={s.cartContainer}>
          {isEmpty ? (
            <div className={s.left}>
              <div className={s.cartItems}>
                <EmptyBox
                  shoppingInfo={shoppingInfo}
                  locale={locale}
                  setRelativeLink={setRelativeLink}
                />
                {/* 产品推荐 */}
                <Recommends
                  personalizeProducts={personalizeProducts}
                  recommendsProducts={recommendsProducts}
                  cartRecommends={cartRecommends}
                  shoppingInfo={shoppingInfo}
                  getBundleSize={getBundleSize}
                  setRecommendsAddedSkuAdded={setRecommendsAddedSkuAdded}
                  data={data}
                  shopCommon={shopCommon}
                  show={show}
                  applyCode={applyCode}
                />
              </div>
            </div>
          ) : (
            <div className={s.left}>
              {/* 免邮 only us */}
              {(shoppingInfo?.main ||
                gradient_gifts?.cartUnReach ||
                summerCampaignDiscount?.cartUnReach ||
                fridayBlackChg2023) && (
                <div className={cn(s.shoppingInfo, 'bg-white')}>
                  <Slider
                    {...{
                      arrows: false,
                      dots: false,
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      autoplay: true,
                      autoplaySpeed: 6000,
                      speed: 300,
                    }}
                  >
                    {(shoppingInfo?.membersText || shoppingInfo?.main) && (
                      <div>
                        <div
                          className={cn(s.shipping, {
                            [s.memberWrap]: shoppingInfo?.membersText,
                          })}
                        >
                          <Text
                            html={
                              shoppingInfo?.membersText || shoppingInfo?.main
                            }
                          />
                        </div>
                      </div>
                    )}

                    {nextSummerLevel && summerCampaignDiscount?.cartUnReach && (
                      <div>
                        <CampainModal
                          className="w-full"
                          key="summerCampaignDiscount-CampainModal"
                          lineItems={data?.lineItems}
                          campain={summerCampaignDiscount}
                          tags={summerCampaignDiscount?.includeTags}
                          campainLevel={nextSummerLevel}
                          involvedSubTotal={involvedSubTotal}
                          allDiscount={allDiscount}
                          metafields={metafields}
                        >
                          <Text
                            className={s.campaignInfo}
                            html={summerCampaignDiscount?.cartUnReach
                              ?.replace('$product', nextSummerLevel.description)
                              ?.replace(
                                '$value',
                                formatPrice({
                                  amount: new Decimal(
                                    nextSummerLevel.breakpoint
                                  )
                                    .minus(involvedSubTotal)
                                    .toNumber(),
                                  currencyCode: data?.currency?.code,
                                  locale,
                                })
                              )}
                          />
                        </CampainModal>
                      </div>
                    )}

                    {nextLevel && gradient_gifts?.cartUnReach && (
                      <div>
                        <CampainModal
                          className="w-full"
                          lineItems={data?.lineItems}
                          key="gradient_gifts-CampainModal"
                          campain={gradient_gifts}
                          tags={gradient_gifts?.includeTags}
                          campainLevel={nextLevel}
                          involvedSubTotal={nextInvolvedSubTotal}
                          allDiscount={allDiscount}
                          metafields={metafields}
                        >
                          <Text
                            className={s.campaignInfo}
                            html={gradient_gifts?.cartUnReach
                              ?.replace('$product', nextLevel.description)
                              ?.replace(
                                '$value',
                                formatPrice({
                                  amount: new Decimal(nextLevel.breakpoint)
                                    .minus(nextInvolvedSubTotal)
                                    .toNumber(),
                                  currencyCode: data?.currency?.code,
                                  locale,
                                })
                              )}
                          />
                        </CampainModal>
                      </div>
                    )}

                    {hasSolixFanPro && (
                      <div>
                        <Text
                          className={s.campaignInfo}
                          html={solixFanCart?.tips}
                        ></Text>
                      </div>
                    )}

                    {productMix && fridayBlackChg2023?.cart_tips && (
                      <div>
                        <CampainModal
                          className="mt-1  w-full"
                          key="fridayBlackChg2023-CampainModal"
                          lineItems={data?.lineItems}
                          campain={fridayBlackChg2023}
                          tags={fridayBlackChg2023?.tags}
                          allDiscount={allDiscount}
                          metafields={metafields}
                        >
                          <Text
                            className={s.campaignInfo}
                            html={fridayBlackChg2023.cart_tips?.replace(
                              '$price',
                              minimum
                            )}
                          />
                        </CampainModal>
                      </div>
                    )}
                  </Slider>
                </div>
              )}

              <CartBanner metafields={metafields} />
              {/* 主内容部分 */}
              {gradient_gifts?.cartDescription && (
                <div className="">
                  <ShoppingCartGifts lang={gradient_gifts} />
                </div>
              )}

              <div className={s.cartItems}>
                {/* 商品 List */}
                <ul className="border-accent-2 bg-white px-9 md:px-5">
                  {sortBySkuAndRelated(
                    data?.lineItems?.sort(reorder)?.sort(summerReorder)
                  )?.map((item, index) => (
                    <CartItem
                      form="CartSidebarView"
                      key={`${item.id}${item.variant.price}${index}`}
                      item={item}
                      shoppingInfo={shoppingInfo}
                      summerLevel={summerLevel}
                      gradientLevel={gradientLevel}
                      bundleItem={bundleItem?.[item?.id]}
                      position={index + 1}
                      freegift_related={item?.freegift_related}
                      currencyCode={data?.currency?.code}
                      shopCommon={shopCommon}
                      allSku={data?.lineItems?.map(
                        (item) => item?.variant?.sku
                      )}
                      // removeItemCbk={handleRemoveItem}
                      quantityBreakDiscountData={quantityBreakDiscountData}
                      quantityBreakDescription={
                        showLearnMore &&
                        gradientGiftsLines?.length === 0 &&
                        summerCampaignLines?.length === 0 && (
                          <>
                            <DiscountDescription
                              data={quantityBreakDiscountData}
                            />
                            {/* 当前有应用上的 code 不且是满减的 code 且已满足满减情况提示用户 */}
                            {isNormalCode(appliedCode) && quantityBreakCode && (
                              <Text
                                html={quantityBreakDiscountData?.unAvailable}
                                className="text-xs text-red md:text-[3vw]"
                                variant="paragraph"
                              />
                            )}
                          </>
                        )
                      }
                    >
                      {item?.freegift_related?.length > 0 &&
                        item?.freegift_related?.map(
                          (freegift_related_item, frindex) => (
                            <CartItem
                              form="CartSidebarView"
                              key={`${freegift_related_item.id}${freegift_related_item.variant.price}${frindex}`}
                              item={freegift_related_item}
                              summerLevel={summerLevel}
                              shoppingInfo={shoppingInfo}
                              gradientLevel={gradientLevel}
                              bundleItem={
                                bundleItem?.[freegift_related_item?.id]
                              }
                              position={index + 1}
                              currencyCode={data?.currency?.code}
                              shopCommon={shopCommon}
                              allSku={data?.lineItems?.map(
                                (item) => item?.variant?.sku
                              )}
                              // removeItemCbk={handleRemoveItem}
                              quantityBreakDiscountData={
                                quantityBreakDiscountData
                              }
                              quantityBreakDescription={
                                showLearnMore &&
                                gradientGiftsLines?.length === 0 &&
                                summerCampaignLines?.length === 0 && (
                                  <>
                                    <DiscountDescription
                                      data={quantityBreakDiscountData}
                                    />
                                    {/* 当前有应用上的 code 不且是满减的 code 且已满足满减情况提示用户 */}
                                    {isNormalCode(appliedCode) &&
                                      quantityBreakCode && (
                                        <Text
                                          html={
                                            quantityBreakDiscountData?.unAvailable
                                          }
                                          className="text-xs text-red md:text-[3vw]"
                                          variant="paragraph"
                                        />
                                      )}
                                  </>
                                )
                              }
                            />
                          )
                        )}
                    </CartItem>
                  ))}
                </ul>
                <Recommends
                  personalizeProducts={personalizeProducts}
                  recommendsProducts={recommendsProducts}
                  cartRecommends={cartRecommends}
                  shoppingInfo={shoppingInfo}
                  getBundleSize={getBundleSize}
                  setRecommendsAddedSkuAdded={setRecommendsAddedSkuAdded}
                  data={data}
                  shopCommon={shopCommon}
                  show={show}
                  applyCode={applyCode}
                />
                {shoppingInfo?.hes_appointment_method &&
                  has_appointment_tags?.length > 0 && (
                    <LogisticsInput
                      loading={isLoading}
                      shoppingInfo={shoppingInfo}
                      initAttributes={data?.customAttributes?.find(
                        (attribute) =>
                          attribute.key ===
                          shoppingInfo?.hes_appointment_method?.attribute_key
                      )}
                      onConfirm={(val) => {
                        cartAttributesUpdate({
                          customAttributes: [
                            {
                              key: shoppingInfo?.hes_appointment_method
                                ?.attribute_key,
                              value: val,
                            },
                          ],
                        })
                      }}
                    />
                  )}

                {/* 卖点部分 */}
                <div className={cn(s.reasons, BRAND)}>
                  <Text
                    className={cn(s.title, '!pb-2')}
                    html={infos?.store_iconBar?.title}
                    variant="paragraph"
                  />
                  <ProductIconBar
                    iconBars={infos?.store_iconBar?.data}
                    direction={infos?.store_iconBar?.direction}
                    s={{
                      iconBars: 'grid grid-cols-2 gap-x-5 gap-y-2',
                      iconBar: 'flex items-center gap-1',
                      barText:
                        'text-sm font-[500] text-[#777] mt-[2px] text-left leading-[18px]',
                      icon: 'w-[24px] h-[24px] flex-shrink-0',
                    }}
                  />
                </div>
              </div>
              {/* 底部悬浮条 */}
              <div className={s.stickyBar}>
                {shoppingInfo?.showDiscounts && (
                  <CodeInput
                    shoppingInfo={shoppingInfo}
                    codeError={codeError}
                    onSubmit={applyCode}
                    setCodeError={setCodeError}
                    codeApplying={codeApplying}
                    codeRemoving={codeRemoving}
                    handleCodeRemove={handleCodeRemove}
                    discountCodes={data?.discountCodes}
                    setCodeDeleteModal={setCodeDeleteModal}
                    // codeTips={codeColseTips && fridayBlackChg2023?.code_tips}
                    codeTips={codeColseTips}
                    data={data}
                    s={s}
                  />
                )}

                <motion.div
                  className=" overflow-hidden"
                  animate={showFixed ? 'show' : 'hidden'}
                  variants={{
                    hidden: {
                      height: 0,
                    },
                    show: {
                      height: 'auto',
                    },
                  }}
                >
                  {/* subtotal */}
                  <div className="flex justify-between pb-2">
                    <span>{shoppingInfo?.subtotal}</span>
                    <span className="font-semibold">{subTotal}</span>
                  </div>
                  {(appliedOrderCode?.length > 0 ||
                    data?.totalTaxAmount > 0) && (
                    <ul className="pb-2">
                      {/* order discount */}
                      {hasDiscount &&
                        appliedOrderCode &&
                        appliedOrderCode?.length > 0 && (
                          <li className={s.discountTxt}>
                            <div className="flex w-full">
                              <span className="leading-7">
                                {shoppingInfo?.discount}
                              </span>
                              {/* 折扣标签 */}
                              <div className="grow">
                                {appliedOrderCode?.map((item, i) => {
                                  return (
                                    <div
                                      className="flex justify-between"
                                      key={`order-${item?.code}-${i}`}
                                    >
                                      <div className={s.codeIconBox}>
                                        <TagIcon className="mr-1 inline-block h-4 w-4 align-text-bottom"></TagIcon>
                                        <Text
                                          variant="inline"
                                          html={item.code}
                                          className="align-middle"
                                        />
                                      </div>
                                      <span className="leading-7">
                                        {item.amountFormat}
                                      </span>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          </li>
                        )}
                    </ul>
                  )}
                  {/* 邮费税率 */}
                  {shoppingInfo?.shipping && (
                    <div className="flex justify-between pb-2">
                      <span>{shoppingInfo?.shipping}</span>
                      <span className="font-semibold tracking-wide">
                        {shoppingInfo?.free}
                      </span>
                    </div>
                  )}
                  {data?.totalTaxAmount > 0 && (
                    <div className="flex justify-between pb-2">
                      <span>
                        {shoppingInfo?.estimatedTaxes || 'Estimated taxes'}
                      </span>
                      <span className="font-semibold">{totalTaxAmount}</span>
                    </div>
                  )}
                  {shoppingInfo?.taxes && data?.totalTaxAmount <= 0 && (
                    <div className="flex justify-between pb-2">
                      <span>{shoppingInfo?.taxes}</span>
                      <span className="font-semibold">
                        {shoppingInfo?.taxesInfo}
                      </span>
                    </div>
                  )}
                </motion.div>

                {(data?.discountCodes.length > 0 ||
                  data?.totallineItemsDiscount > 0) && (
                  <div className="flex justify-between pb-2">
                    <span>{shoppingInfo?.discount}</span>
                    <span className="font-semibold text-[#FF9900]">
                      {totallineItemsDiscount}
                    </span>
                  </div>
                )}

                {/* total */}
                <div className="mb-2 flex items-end justify-between pt-1 font-medium">
                  <span className="text-base">{shoppingInfo?.total}</span>
                  <span className="text-[22px] font-bold" tag="Auto-10000413">
                    {
                      // total 包含预估税额,预估税额现在逻辑没理清楚，暂时使用subTotal展示
                      // total
                      total?.split('.')?.map((item, i) => (
                        <span key={i + item} className="even:text-base">
                          {i === 1 ? '.' : ''}
                          {item}
                        </span>
                      ))
                    }
                  </span>
                </div>
                {Number(data?.totalPrice) > 100 && (
                  <InstalmentsModal
                    className="!pt-0 pb-1"
                    pageType={pageType}
                    metafields={metafields}
                  >
                    {thirdparty?.paypal?.src && (
                      <div className="border-b border-[#D9D9D9] px-4 py-4">
                        <Paypal price={data?.totalPrice} />
                      </div>
                    )}
                    {thirdparty?.consors_finance?.button &&
                      thirdparty?.consors_finance?.url && (
                        <a
                          href={thirdparty?.consors_finance?.url}
                          target={
                            thirdparty?.consors_finance?.target || '_blank'
                          }
                          className={s.consorsFinanceA}
                        >
                          {thirdparty?.consors_finance?.button}
                        </a>
                      )}
                    {(preRender?.hideKlarna !== undefined
                      ? !preRender?.hideKlarna
                      : !shopPreRender?.hideKlarna) && (
                      <div className="border-b border-[#D9D9D9] px-4 py-4">
                        <Klarna
                          price={data?.totalPrice}
                          settings={metafields?.klarnaSettings}
                        />
                      </div>
                    )}
                    {(preRender?.hideAffirm !== undefined
                      ? !preRender?.hideAffirm
                      : !shopPreRender?.hideAffirm) && (
                      <div className="px-4 py-4">
                        <Affirm price={data?.totalPrice} />
                      </div>
                    )}
                  </InstalmentsModal>
                )}
                {/* checkout 按钮 */}
                <div className="flex items-center gap-3">
                  <button
                    onClick={() => setShowFixed(!showFixed)}
                    className="flex flex-none gap-1 text-sm opacity-60"
                  >
                    <span>{shoppingInfo?.checkDetails || 'Check Details'}</span>
                    <ChevronDownIcon
                      className={`${
                        !showFixed && 'rotate-180'
                      } mt-[2px] transition-all`}
                      width={16}
                      height={16}
                    />
                  </button>
                  <Button
                    tag="Auto-10000414"
                    className="min-h-[42px] flex-grow !rounded-none"
                    width="100%"
                    disabled={tipError}
                    id="netlify_dtctest_checkout"
                    loading={loadingTocheckout}
                    onClick={async () => {
                      if (tipError) return
                      goToCheckout(data, appliedCode)
                      beginCheckout({
                        data,
                        group: getGtmGroup({ query, asPath }),
                      })
                    }}
                  >
                    {shoppingInfo?.checkoutBtn}
                  </Button>
                </div>
              </div>
              <Modal
                isOpen={!!codeDeleteModal}
                ariaHideApp={false}
                className="Modal"
                overlayClassName="Overlay"
                contentLabel="Code Delete comfire"
                style={{
                  overlay: {
                    zIndex: '1000001',
                  },
                  content: {
                    width: '100%',
                    maxWidth: '500px',
                    borderRadius: '0',
                  },
                }}
              >
                <button
                  className="absolute right-1 top-1 p-2"
                  onClick={() => setCodeDeleteModal(false)}
                >
                  <Close width="20" height="20" />
                </button>
                <div className="px-10 py-8 pb-6">
                  <Text
                    className="text-xl font-medium leading-[1.3]"
                    html={shoppingInfo?.deleteDiscountTitle?.replace(
                      '{code}',
                      codeDeleteModal
                    )}
                  />

                  <div className="mt-5 flex justify-center gap-5">
                    <Button
                      variant="secondary"
                      className="w-[50%] min-w-[100px] !rounded-none px-5 font-semibold"
                      onClick={() => setCodeDeleteModal(false)}
                    >
                      {shopCommon?.cancel || 'Cancel'}
                    </Button>
                    <Button
                      className="w-[50%] min-w-[100px] !rounded-none font-semibold"
                      onClick={() => {
                        handleCodeRemove(codeDeleteModal)
                        setCodeDeleteModal(false)
                      }}
                      loading={codeApplying}
                    >
                      {shopCommon?.confirm || 'Confirm'}
                    </Button>
                  </div>
                </div>
              </Modal>
            </div>
          )}
        </div>
      )}
    </SidebarLayout>
  )
}

export default CartSidebarView

const EmptyBox = ({ shoppingInfo, locale, setRelativeLink }) => {
  return (
    <div className="flex flex-1 flex-col items-center bg-white px-4 pb-12">
      <span className="flex w-full max-w-[400px] items-center justify-center pb-5 pt-8">
        <Picture
          className="h-auto w-[60%]"
          imgClassName="w-full h-full object-contain"
          source="https://cdn.shopify.com/s/files/1/0493/9834/9974/files/image-null.png?v=1716949709"
          alt={shoppingInfo?.emptyCart}
        />
      </span>
      <h2 className="text-center text-lg font-medium">
        {shoppingInfo?.emptyCart}
      </h2>
      <a
        href={
          shoppingInfo?.continueLink
            ? setRelativeLink({ link: shoppingInfo.continueLink })
            : `${locale === 'us' ? '' : '/' + locale}/collections/all`
        }
        className="mt-3 h-[50px] overflow-hidden bg-anker-color px-6 text-center text-base font-medium leading-[50px] text-white"
      >
        {shoppingInfo?.continue}
      </a>
    </div>
  )
}
