import { useEffect, useState, useCallback, useMemo } from 'react'
import cn from 'classnames'
import Modal from 'react-modal'
import { useTimer } from 'react-timer-hook'
import { useRouter } from 'next/router'
import { formatPrice } from '@commerce/product/use-price'
import { Price, ProductPrice } from '@components/product'
import { Close } from '@components/icons/next'
import { Button, Text, Picture } from '@components/ui'
import { normalizeStorefrontProduct } from '@components/normalize'
import { atobID } from '@lib/utils/tools'
import BannerLink from '@components/common/BannerLink'
import { useSearch } from 'lib/shopify/api/search'
import { useAddItem, useCodeApply } from 'lib/shopify/api/cart'

import Decimal from 'decimal.js'

const CountDown = ({ data }) => {
  const timer = useTimer({
    expiryTimestamp: new Date(data.endTime),
  })

  console.log('timer', timer)

  return <div className="flex justify-center"></div>
}

const CampainModal = ({
  children,
  tags,
  first,
  lineItems,
  campain,
  campainLevel,
  involvedSubTotal,
  className,
  allDiscount,
  metafields,
  ...props
}) => {
  const { locale } = useRouter()
  const addItem = useAddItem()
  const codeApply = useCodeApply()

  const { pageCommon, shopCommon } = metafields
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [select, setSelect] = useState([])

  console.log('tags', tags)

  const productFilters = tags?.map((tag) => ({ tag, available: true }))

  const { data: searchResult } = useSearch({
    searchTerm: '',
    first: first || 50,
    locale,
    productFilters: productFilters || [],
  })

  const searchProducts = useMemo(() => {
    if (searchResult?.items?.length) {
      return searchResult?.items?.filter(
        (item) =>
          !!item?.availableForSale && item?.tags?.some((t) => tags?.includes(t))
      )
    }
    return []
  }, [searchResult, lineItems])

  const allProducts = useMemo(
    () =>
      searchProducts
        ?.flatMap((item) => {
          return item?.variants
            ?.map((v_item) => {
              return normalizeStorefrontProduct(
                {
                  handle: item?.handle,
                  sku: v_item?.sku,
                },
                {
                  products: searchProducts,
                },
                allDiscount
              )
            })
            ?.filter((item) => !!item?.availableForSale)
        })
        // ?.sort((a, b) => b.price - a.price)
        ?.slice(0, 20),
    [searchProducts, allDiscount]
  )

  const [selectTotal, selectSubTotal] = useMemo(() => {
    return select?.reduce(
      (pre, cur) => {
        const [total, subtotal] = pre
        const cur_total = new Decimal(total).plus(cur?.price)
        const cur_subtotal = new Decimal(subtotal).plus(cur?.originPrice)
        return [Number(cur_total), Number(cur_subtotal)]
      },
      [0, 0]
    )
  }, [select])

  const minimum = useMemo(() => {
    if (!campain?.minimum?.spend) return ''
    const chgPro = lineItems?.reduce((prev, item) => {
      const in_spend = item?.product?.tags?.some((tag) =>
        campain?.tags?.includes(tag)
      )
      if (in_spend) {
        return new Decimal(prev).plus(new Decimal(item?.totalAmount))
      }
      return prev
    }, selectTotal)

    const sub = new Decimal(new Decimal(campain?.minimum?.spend))
      .minus(chgPro)
      .toNumber()

    const spend =
      sub > 0
        ? formatPrice({
            amount: sub,
            currencyCode:
              searchProducts?.[0]?.price?.currencyCode ||
              allProducts?.[0]?.currencyCode ||
              'USD',
            locale,
          })
        : 'none'
    return spend
  }, [lineItems, selectTotal])

  const modal_tip = useMemo(() => {
    if (campain?.cart_tips && minimum !== 'none') {
      return campain?.cart_tips?.replace('$price', minimum)
    }
    if (campainLevel) {
      // if (selectTotal > campainLevel.breakpoint) return '满足条件'
      return campain?.cartUnReach
        ?.replace('$product', campainLevel.description)
        ?.replace(
          '$value',
          formatPrice({
            amount: new Decimal(campainLevel.breakpoint)
              .minus(involvedSubTotal)
              .toNumber(),
            currencyCode:
              searchProducts?.[0]?.price?.currencyCode ||
              allProducts?.[0]?.currencyCode ||
              'USD',
            locale,
          })
        )
    }
    return ''
  }, [campainLevel, involvedSubTotal, selectTotal, minimum])

  const addtocart = useCallback(async (products) => {
    const appliedCode = []
    const lineItems = products?.map((product) => {
      if (product?.code) appliedCode.push(product?.code)
      return {
        variantId: String(product?.variantId),
        quantity: product?.quantity || 1,
      }
    })
    setLoading(true)
    try {
      await addItem(lineItems)
      if (appliedCode) {
        setTimeout(() => codeApply({ discountCode: appliedCode }))
      }
      setShowModal(false)
      setLoading(false)
      setSelect([])
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }, [])

  const amountFormat = useCallback(
    (amount, currencyCode) => {
      const currency =
        currencyCode ||
        searchProducts?.[0]?.price?.currencyCode ||
        allProducts?.[0]?.currencyCode ||
        'USD'
      return formatPrice({
        amount: Number(amount),
        locale,
        currencyCode: currency,
        maximumFractionDigits: 2,
      })
    },
    [searchProducts]
  )

  return (
    <>
      <style jsx global>{`
        .Modal.ReactModal__Content {
          border-radius: 0;
        }
        @media (max-width: 768px) {
          .Modal.ReactModal__Content {
            height: 92vh;
            bottom: 0;
            top: auto;
            transform: translate(-50%, 0);
          }
        }
      `}</style>
      <button className={className} onClick={() => setShowModal(true)}>
        {children}
      </button>
      <Modal
        isOpen={!!showModal}
        ariaHideApp={false}
        className="Modal"
        overlayClassName="Overlay"
        contentLabel="Code Delete comfire"
        style={{
          overlay: {
            zIndex: '1000001',
          },
          content: {
            width: '100%',
            maxWidth: '1000px',
            background: '#F7F8F9',
          },
        }}
      >
        <button
          className="absolute right-1 top-1 p-2"
          onClick={() => {
            setShowModal(false)
            setLoading(false)
          }}
        >
          <Close width="20" height="20" />
        </button>
        <div className="flex flex-col px-10 pt-8 md:px-4">
          <Text
            className="mb-5 flex-none text-center text-xl font-semibold leading-[1.3]"
            html="Make an order together"
          />

          {(modal_tip || campain?.tag_tip) && (
            <div className="mb-4 flex flex-wrap items-center justify-between rounded-sm bg-[#f8eee0] px-4 py-2 text-sm font-semibold text-[#333] md:text-xs">
              <div className="flex items-center gap-1">
                {campain?.tag_tip && (
                  <Text
                    className="rounded-sm bg-[#fe5c00] px-1 pb-[2px] pt-[3px] text-xs text-white"
                    html={campain?.tag_tip}
                  />
                )}
                <Text html={modal_tip} />
              </div>
              {campain?.countDown?.text && (
                <Text className="md:mt-1" html={campain?.countDown?.text} />
              )}
            </div>
          )}

          <div className="h-[50vh] shrink overflow-y-auto py-3 pr-4">
            <div className="grid grid-cols-4 gap-4 md:grid-cols-2">
              {allProducts?.map((product, index) => {
                return (
                  <Card
                    key={index}
                    pageCommon={pageCommon}
                    shopCommon={shopCommon}
                    product={product}
                    setSelect={setSelect}
                    select={select}
                  ></Card>
                )
              })}
            </div>
          </div>

          <div className="flex flex-none items-center justify-end gap-5 border-t border-[#E8E8E8] px-4 py-6 md:px-4">
            <ProductPrice
              className=""
              styles={{
                price: 'text-[22px] font-semibold md:text-[16px] text-[#000]',
                basePrice: 'text-[14px] md:text-[12px] text-[#BBBBBB]',
              }}
              price={amountFormat(selectTotal)}
              basePrice={
                selectTotal !== selectSubTotal
                  ? amountFormat(selectSubTotal)
                  : false
              }
              settings={shopCommon}
              availableForSale
            />
            <Button
              className="min-h-[42px] min-w-[160px] !rounded-none font-medium md:min-w-[100px]"
              disabled={selectTotal === 0}
              loading={loading}
              onClick={() => {
                addtocart(select)
              }}
            >
              {shopCommon?.add_to_Cart || 'Add to Cart'}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
export default CampainModal

export const Card = ({
  pageCommon,
  select,
  setSelect,
  shopCommon,
  product,
}) => {
  const { locale } = useRouter()

  const active = useMemo(() => {
    return select?.some((item) => item?.variantId === product?.variantId)
  }, [select])

  const selectfn = useCallback(() => {
    const copy_select = [...select]
    if (active) {
      setSelect(
        copy_select?.filter((item) => item?.variantId !== product?.variantId)
      )
    } else {
      copy_select.push(product)
      setSelect(copy_select)
    }
  }, [select])

  const discount = useMemo(() => {
    return product.discountValue
      ? formatPrice({
          amount: parseInt(product.discountValue),
          locale,
          currencyCode: product.currencyCode,
          maximumFractionDigits: 0,
        })
      : ''
  }, [product])

  if (!product) return null

  return (
    <button
      className={cn(
        'relative flex flex-col items-stretch border-2 border-[#f7f8f9] bg-white text-left',
        { '!border-[#00BEFA]': active }
      )}
      onClick={selectfn}
    >
      {discount && (
        <div className="absolute overflow-hidden w-[90px] left-0 top-0 z-10">
          <svg
            className=" scale-125"
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="28"
            viewBox="0 0 80 28"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M80 0.0227581V0H0V28H66.0511L80 0.0227581Z"
              fill="#DAEDFF"
            />
          </svg>
          <span
            className="absolute left-[45%] top-1/2 flex -translate-x-1/2 -translate-y-1/2 whitespace-nowrap text-sm font-medium"
            style={{
              background: 'linear-gradient(90deg, #0AF 0%, #0082FF 50%)',
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            {shopCommon?.save || ''} {discount}
          </span>
        </div>
      )}

      <div className="relative flex items-center justify-center">
        <Picture
          source={product.image}
          className={
            'h-[150px] md:h-[110px] [&_img]:!h-full [&_img]:w-full [&_img]:object-contain'
          }
        />
      </div>
      <div
        className={
          'md:py-0-[12px] flex h-full flex-col justify-between px-[18px] py-[20px] md:px-[8px]'
        }
      >
        <div className="relative">
          <BannerLink
            to={`${locale === 'us' ? '' : '/' + locale}/products/${
              product.handle
            }?variant=${atobID(product.variantId)}&ref=cart`}
          />
          <Text
            html={product.title}
            className={
              'flex-1 text-sm font-semibold text-[#000] md:text-[12px]'
            }
            variant="paragraph"
          />
        </div>
        {/* 产品选项 */}
        {product?.options && product?.options?.length > 0 && (
          <div className="text-sm text-[#999]">
            {product?.options
              ?.map((option, i) => option?.values?.[0]?.label)
              ?.filter((v) => v !== 'Default Title')
              ?.join(' / ')}
          </div>
        )}
        <Price
          copy={pageCommon}
          product={product}
          className="mt-[12px]"
          s={{
            price: 'text-base md:text-[16px] text-[#000]',
            basePrice: 'text-sm md:text-[12px] text-[#BBBBBB]',
          }}
        />
      </div>
    </button>
  )
}
