import metafieldsItems from '../fragments/metafields'

export const searchQuery = /* GraphQL */ `
  query SearchQuery(
    $query: String!
    $first: Int
    $last: Int
    $sortKey: SearchSortKeys
    $productFilters: [ProductFilter!]
    $types: [SearchType!]
    $reverse: Boolean
    $after: String
    $before: String
  ) {
    search(query: $query, first: $first, last: $last, sortKey: $sortKey, productFilters: $productFilters, types:$types, reverse: $reverse, after: $after, before: $before) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        node {
          ... on Product {
            __typename
            id
            handle
            availableForSale
            title
            productType
            vendor
            description
            descriptionHtml
            onlineStoreUrl
            publishedAt
            totalInventory
            tags
            priceRange {
              maxVariantPrice {
                amount
                currencyCode
              }
              minVariantPrice {
                amount
                currencyCode
              }
            }
            compareAtPriceRange {
              minVariantPrice {
                amount
                currencyCode
              }
            }
            images(first: 250) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              edges {
                node {
                  originalSrc
                  altText
                  width
                  height
                }
              }
            }
            variants(first: 250) {
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
              edges {
                node {
                  id
                  title
                  sku
                  barcode
                  availableForSale
                  requiresShipping
                  quantityAvailable
                  currentlyNotInStock
                  selectedOptions {
                    name
                    value
                  }
                  image {
                    altText
                    url
                    width
                    height
                  }
                  priceV2 {
                    amount
                    currencyCode
                  }
                  compareAtPriceV2 {
                    amount
                    currencyCode
                  }
                  MF_listing: metafield(namespace: "tags", key: "listing") {
                    ${metafieldsItems}
                  }
                  MF_presell: metafield(namespace: "global", key: "presell") {
                    ${metafieldsItems}
                  }
                  MF_cartInfo: metafield(namespace: "cart", key: "info") {
                    ${metafieldsItems}
                  }
                  MF_image: metafield(namespace: "banner", key: "image") {
                    ${metafieldsItems}
                  }
                  MF_images: metafield(namespace: "variant", key: "images") {
                    ${metafieldsItems}
                  }
                  MF_infos: metafield(namespace: "variant", key: "infos") {
                    ${metafieldsItems}
                  }
                  MF_logistics: metafield(namespace: "variant", key: "logistics") {
                    ${metafieldsItems}
                  }
                  MF_variantSurprisePack: metafield(namespace: "variant", key: "surprisePack") {
                    ${metafieldsItems}
                  }
                  MF_variantAccessories: metafield(namespace: "variant", key: "accessories") {
                    ${metafieldsItems}
                  }
                  MF_discounts: metafield(namespace: "discounts", key: "discounts") {
                    ${metafieldsItems}
                  }
                  MF_gallery: metafield(namespace: "variant", key: "gallery") {
                    ${metafieldsItems}
                  }
                  MF_freeGift: metafield(namespace: "free_gift", key: "freeGift") {
                    ${metafieldsItems}
                  }
                  MF_variantTags: metafield(namespace: "variant", key: "tag") {
                    ${metafieldsItems}
                  }
                }
              }
            }
            MF_seoSetting: metafield(namespace: "seo", key: "setting") {
              ${metafieldsItems}
            }
            MF_downloads: metafield(namespace: "component", key: "productDownloads") {
              ${metafieldsItems}
            }
          }
          ... on Page {
            __typename
            id
            title
            handle
            body
            bodySummary
            MF_seoSetting: metafield(namespace: "seo", key: "setting") {
              ${metafieldsItems}
            }
          }
          ... on Article {
            __typename
            id
            publishedAt
            title
            handle
            content
            contentHtml
            image {
              url
            }
            blog {
              handle
            }
            MF_seoSetting: metafield(namespace: "seo", key: "setting") {
              ${metafieldsItems}
            }
          }
        }
      }
    }
  }
`
