import React from 'react'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import cn from 'classnames'
import 'regenerator-runtime/runtime'
import s from './index.module.css'

/**
 * affirm分期
 * props: variant
 */
const Affirm = ({ price, variant, className }) => {
  return (
    <p
      className={cn(s.affirm, className, 'affirm-as-low-as')}
      data-page-type="product"
      data-amount={(price || variant?.price) * 100}
      onClick={(e) => {
        pageGTMEvent({
          event: 'uaEvent', // Trigger
          eventCategory: 'Product Detail Page_' + (variant?.sku || 'cart'),
          eventAction: 'affirm',
          eventLabel: e?.target?.innerText, //取按钮文案
        })
        pageGTMEvent({
          event: 'ga4Event',
          event_name: 'lp_button',
          event_parameters: {
            page_group: 'Product Detail Page_' + (variant?.sku || 'cart'),
            button_name: e?.target?.innerText, //取按钮文案
            position: 'affirm',
          },
        })
      }}
    ></p>
  )
}

export default Affirm
