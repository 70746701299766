import cn from 'classnames'
import { useState, useMemo, useEffect } from 'react'
import { useRouter } from 'next/router'
import { Button, Text, Picture } from '@components/ui'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { pageGTMEvent } from '@lib/utils/thirdparty'

/**
 * affirm分期
 * props: variant
 */
const InstalmentsModal = ({ className, metafields, pageType, children }) => {
  const { thirdparty, shopCommon } = metafields || {}

  const { locale } = useRouter()

  const [modalOpen, setModalOpen] = useState(false)

  return thirdparty?.instalments ? (
    <>
      <div className={cn('px-1 pb-0 pt-2', className)}>
        <button
          className="flex w-full items-center justify-end gap-1 rounded"
          onClick={() => {
            setModalOpen(true)
            pageGTMEvent({
              event: 'ga4Event',
              event_name: 'lp_button',
              plus_member_status: '',
              event_parameters: {
                page_group: 'Activity Page_' + document.location.href,
                position: 'instalments',
                button_name: thirdparty?.instalments?.modal_btn,
              },
            })
          }}
        >
          <div className="flex items-center justify-start gap-2">
            <Text
              className="mt-1 text-left text-sm font-medium text-[#999]"
              html={thirdparty?.instalments?.modal_btn}
            />
          </div>
          <Picture
            className="w-4 h-4"
            imgClassName="w-full h-full object-contain"
            source="https://cdn.shopify.com/s/files/1/0491/8460/4324/files/Frame_3.png?v=1730953389"
            alt=""
          />
        </button>
      </div>
      <div className={cn(`mask_modal ${!modalOpen && 'hidden'}`)}>
        <div className="modal_box">
          <button className="absolute right-3 top-3 cursor-pointer">
            <XMarkIcon
              className="h-6 w-6 "
              onClick={() => {
                setModalOpen(false)
              }}
            />
          </button>
          <div className="px-8 pb-6 pt-8">
            <Text
              className="mb-5 mt-2 text-center text-xl font-semibold md:text-base"
              html={thirdparty?.instalments?.modal_title}
            />
            <div className="grid border border-[#D9D9D9]">{children}</div>
          </div>
        </div>
      </div>
    </>
  ) : (
    ''
  )
}

export default InstalmentsModal
