import Cookies from 'js-cookie'
import useSWR from 'swr'
import { useMemo } from 'react'
import { useRouter } from 'next/router'

import { Search as SearchType } from 'lib/shopify/types'
import { normalizeSearchResult } from 'lib/utils/normalize'
import fetchGraphqlApi from 'lib/config/fetch-graphql-api'
import { searchQuery } from 'lib/shopify/queries/search'

export const validSortKey = ['PRICE', 'RELEVANCE']

export async function search({
  input,
}: {
  input: SearchType.SearchProductsInput
}) {
  const {
    locale,
    searchTerm,
    first,
    sortKey,
    productFilters,
    types,
    reverse,
    after,
    before,
  } = input

  const { res } = await fetchGraphqlApi<SearchType.ShopifySearchOperation>({
    locale,
    query: searchQuery,
    variables: {
      first: first || 8,
      sortKey: sortKey || 'RELEVANCE',
      productFilters,
      query: searchTerm,
      types: types || ['ARTICLE', 'PRODUCT'],
      reverse,
      after,
      before,
    },
    cache: 'no-store',
  })

  return normalizeSearchResult(res)
}

export function useSearch(input: SearchType.SearchBody) {
  const { locale = 'us' } = useRouter()

  const response = useSWR({ locale, input, searchQuery }, search)

  return response
}
