import { Text } from '@components/ui'
import { ChevronLeftIcon } from '@heroicons/react/16/solid'

import CartRecommendItem from './CartRecommendItem'
import { viewItems } from '../trace'
import { useDevice } from 'react-use-device'
import { useRef, useEffect, useState } from 'react'
import s from '../CartSidebarView/CartSidebarView.module.css'
import { cn } from '@components/helper'
import { pageGTMEvent } from '@lib/utils/thirdparty'

import { Swiper, SwiperSlide } from 'swiper/react'
import {
  Navigation,
  FreeMode,
  Scrollbar,
  Pagination,
  Autoplay,
} from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/thumbs'
import 'swiper/css/effect-fade'
import 'swiper/css/scrollbar'
import 'swiper/css/free-mode'
import 'swiper/css/pagination'

const DesktopRecommends = ({
  personalizeProducts,
  recommendsProducts,
  cartRecommends,
  shoppingInfo,
  getBundleSize,
  setRecommendsAddedSkuAdded,
  data,
  shopCommon,
  show,
  applyCode,
}) => {
  const hasRecommends =
    personalizeProducts?.length ||
    (cartRecommends?.show && recommendsProducts?.length)
  const { isMOBILE: mobile } = useDevice()
  const swiperRef = useRef(null)
  const [isStart, setStart] = useState(false)
  const [isEnd, setEnd] = useState(false)

  const settings = {
    loop: false,
    speed: 400,
    // spaceBetween: '1.5%',
    // autoplay: {
    //   autoplaySpeed: 3000,
    //   delay: 3000,
    //   stopOnLastSlide: false,
    //   disableOnInteraction: true,
    //   pauseOnMouseEnter: true,
    // },
    navigation: {
      nextEl: `.custom-swiper-button-next`,
      prevEl: `.custom-swiper-button-prev`,
    },
    modules: [Navigation, Pagination, Autoplay],
    slideToClickedSlide: false,
    centeredSlides: false,
    pagination: false,
    breakpoints: {
      0: {
        slidesPerView: 1.8,
        spaceBetween: '12px',
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: '12px',
      },
      1920: {
        slidesPerView: 2,
        spaceBetween: '12px',
      },
    },
    onProgress: (swiper) => {
      setEnd(swiper.isEnd)
      setStart(swiper.isBeginning)
    },
  }

  const PrevArrow = ({ gs = {} }) => {
    return (
      <div
        className={cn(
          'flex h-full w-full items-center justify-center rounded-[100%] bg-[#bbb]',
          gs?.bg
        )}
      >
        <ChevronLeftIcon width={16} height={16} fill="#fff" />
      </div>
    )
  }

  // PC 展开都能看到，全部一起触发 view 事件
  useEffect(() => {
    if (show && !mobile && personalizeProducts?.length) {
      viewItems({
        items: personalizeProducts?.map((product, index) => ({
          item_id: product?.variant?.sku, //读取被推荐产品的SKU
          item_name: product?.name,
          item_variant: '',
          price: product?.variant?.price, //读取被推荐产品展示的折扣价
          index: index, //读取被推荐的位置，如1,2,3
        })),
        skus: data?.lineItems?.reduce(
          (prev, cur) => prev + cur?.variant?.sku + ',',
          ''
        ),
      })
    }
  }, [personalizeProducts?.length])

  return personalizeProducts?.length || recommendsProducts?.length ? (
    <div
      id="PersonalizeRecommendsProducts"
      className={cn(s.recommendModule, hasRecommends ? 'w-full' : 'w-0')}
    >
      {/* 算法推荐商品 */}
      {personalizeProducts?.length > 0 && (
        <div className="w-full">
          <div className="relative">
            <Text
              className={s.recommendsTitle}
              html={shoppingInfo?.recommendsTitle}
              variant="paragraph"
            />
            <div className="absolute right-0 top-1/2 flex -translate-y-1/2 items-center gap-[10px]">
              <div
                className={cn(
                  `custom-swiper-button-prev h-[32px] w-[32px] cursor-pointer`,
                  `${isStart ? 'cursor-not-allowed' : ''}`
                )}
                onClick={() => {
                  pageGTMEvent({
                    event: 'ga4Event',
                    event_name: 'lp_button',
                    plus_member_status: '',
                    event_parameters: {
                      page_group: 'Activity Page_' + document.location.href,
                      position: 'CartRecommed',
                      button_name: 'PrevArrow',
                    },
                  })
                }}
              >
                <PrevArrow
                  gs={{
                    bg: `${
                      isStart
                        ? 'opacity-[0.35] !transition-opacity'
                        : 'opacity-1 transition-opacity'
                    }`,
                  }}
                ></PrevArrow>
              </div>
              <div
                className={cn(
                  `custom-swiper-button-next h-[32px] w-[32px] rotate-180 cursor-pointer`,
                  `${isEnd ? 'cursor-not-allowed' : ''}`
                )}
                onClick={() => {
                  pageGTMEvent({
                    event: 'ga4Event',
                    event_name: 'lp_button',
                    plus_member_status: '',
                    event_parameters: {
                      page_group: 'Activity Page_' + document.location.href,
                      position: 'CartRecommed',
                      button_name: 'NextArrow',
                    },
                  })
                }}
              >
                <PrevArrow
                  gs={{
                    bg: `${
                      isEnd
                        ? 'opacity-[0.35] !transition-opacity'
                        : 'opacity-1 transition-opacity'
                    }`,
                  }}
                ></PrevArrow>
              </div>
            </div>
          </div>

          <Swiper
            {...settings}
            key="personalizeProducts-Swiper"
            ref={swiperRef}
          >
            {personalizeProducts?.map((item, index) => {
              return (
                <SwiperSlide
                  key={`${item?.title}${index}`}
                  className={cn(
                    'relative flex h-full flex-col items-center justify-center overflow-hidden rounded-[4px] text-center l:rounded-[4px]'
                  )}
                >
                  <CartRecommendItem
                    modal="recommends"
                    form="CartSidebarView"
                    key={`${item.id}${index}`}
                    item={item}
                    discountPrice={item?.variant?.__discountPrice || ''}
                    position={index + 1}
                    mainProduct={item?.mainProduct}
                    currencyCode={
                      item?.variant?.currencyCode ||
                      data?.currency?.code ||
                      'USD'
                    }
                    currencySymbol={item?.variant?.currencySymbol}
                    shopCommon={shopCommon}
                    allSku={data?.lineItems?.map((item) => item?.variant?.sku)}
                    getBundleSize={getBundleSize}
                    onRecommendsProductAdd={setRecommendsAddedSkuAdded}
                    swiperRef={swiperRef}
                    applyCode={applyCode}
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      )}

      {/* 运营配置的推荐商品 */}
      {cartRecommends?.show && recommendsProducts?.length > 0 && (
        <div id="RecommendsProducts" className="py-8">
          <div className="px-5 pb-8 md:pb-4">
            <Text
              className={s.recommendsTitle}
              html={shoppingInfo?.recommendsTitle}
              variant="paragraph"
            />
            <ul className="">
              {recommendsProducts.map((item, index) => {
                return (
                  <CartRecommendItem
                    modal="recommends"
                    form="CartSidebarView"
                    key={`recommendsProducts-${item.id}${index}`}
                    item={item}
                    discountPrice={item.__discountPrice || ''}
                    position={index + 1}
                    mainProduct={item.mainProduct}
                    currencyCode={
                      item?.variant?.currencyCode ||
                      data?.currency?.code ||
                      'USD'
                    }
                    currencySymbol={item?.variant?.currencySymbol || ''}
                    shopCommon={shopCommon}
                    allSku={data?.lineItems?.map((item) => item?.variant?.sku)}
                    getBundleSize={getBundleSize}
                    onRecommendsProductAdd={setRecommendsAddedSkuAdded}
                  />
                )
              })}
            </ul>
          </div>
        </div>
      )}
    </div>
  ) : (
    ''
  )
}

export default DesktopRecommends
