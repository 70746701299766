import { useEffect, useState, useCallback } from 'react'
import { sleep } from '@components/helper'
import { useCart } from 'lib/shopify/api/cart'
import { useUI } from '@components/ui'

const useBreakpointDiscount = ({
  quantityBreakDiscountData,
  disabled,
  hasDiscount,
  codeRemove,
  applyCode,
  setCodeError,
  appliedCode,
}) => {
  const { data, isLoading, isEmpty } = useCart()
  const { displaySidebar: show } = useUI()
  const [showLearnMore, setShowLearnMore] = useState(true) //满减 Bundle 页面的入口
  const [quantityBreakCode, setQuantityBreakCode] = useState(null)
  const [quantityBreakCodeRemoved, setQuantityBreakCodeRemoved] =
    useState(false) // 用户删除了满减折扣状态

  const isQuantityBreakCode = (code) =>
    quantityBreakDiscountData?.levels?.find((level) => level.code === code)

  const isNormalCode = (code) => code && !isQuantityBreakCode(code)

  const lineItemsUpdateCbk = useCallback(async () => {
    if (isLoading || disabled) {
      return
    }
    // console.log('-------------------new update----------------------')

    // 每次更新时清除输入框中底下的错误信息
    setCodeError(null)

    /********************** localStorage code => state ***********************/
    const code = appliedCode

    /******************** 必要时删除 code *********************/
    // // 最后一个有 code 的商品移除
    // if (code && isEmpty) {
    //   // console.log('empty cart code remove:')
    //   await codeRemove()
    //   // 提前返回codeRemove
    //   return
    // }
    // 没有折扣时移除 code (当购物车有脚本折扣 + code 折扣时清除 code 也会触发, 先更新成没有折扣再更新成应用脚本折扣)
    if (code && !hasDiscount) {
      // console.log('no discount remove:')
      await codeRemove()
    }

    // /******************** 多折扣时不展示 code *********************/
    // if (data?.discounts?.length >= 2) {
    //   setAppliedCode(null)
    //   localStorage.removeItem(KEY)
    // }

    /********************** 小 B 商品和满减折扣互斥逻辑 ***********************/
    // 是否有小 B 页面加购的商品
    const hasCorporateProducts = Boolean(
      data?.lineItems?.find((item) =>
        item.customAttributes?.find((a) => a.key === '_level_discount')
      )
    )
    // console.log('hasCorporateProducts:', hasCorporateProducts)

    if (hasCorporateProducts) {
      // 当有小 B 商品时取消 bundle 入口展示
      setShowLearnMore(!hasCorporateProducts)
      // 并删掉已经应用的满减折扣
      if (isQuantityBreakCode(code)) {
        await codeRemove()
      }
      // 并提前返回
      return
    }

    /********************** 自动应用 code 逻辑 ***********************/
    // 参与满减的商品数
    const countBreakDiscountsItemsLength = data?.lineItems
      ?.filter(
        (item) =>
          item.product?.tags?.includes(quantityBreakDiscountData?.productTag) &&
          !item.customAttributes?.find(
            (attribute) => attribute.key === '_giveaway'
          )
      )
      ?.reduce((pre, cur) => pre + cur.quantity, 0)
    // console.log('length:', countBreakDiscountsItemsLength)

    const level = quantityBreakDiscountData?.levels
      ?.sort((a, b) => b.quantity - a.quantity)
      ?.find((level) => countBreakDiscountsItemsLength >= level.quantity)
    setQuantityBreakCode(level?.code)

    const newQbCode = level?.code && level?.code !== code
    // console.log('newQbCode', level?.code)

    // 有满减折扣更新，且当前应用的 code 是不是普通 code 时应用, 且不是用户删除了满减 code 的状态
    if (newQbCode && !isNormalCode(code) && !quantityBreakCodeRemoved) {
      // 初次加载延时应用，避免接口超频报错
      if (!show) {
        await sleep(3)
      }
      console.log('level?.code', level?.code)
      await applyCode(level?.code, 'only')
    }
    // 第二次更新时或略这个条件的判断
    setQuantityBreakCodeRemoved(false)
  }, [data?.lineItems, isLoading, isEmpty])

  useEffect(() => {
    lineItemsUpdateCbk()
  }, [lineItemsUpdateCbk])

  return {
    showLearnMore,
    quantityBreakCode,
    setQuantityBreakCodeRemoved,
    isQuantityBreakCode,
    isNormalCode,
  }
}

export default useBreakpointDiscount
