import React from 'react'
import { useRouter } from 'next/router'

import { languageCode } from '@commerce/product/use-price'

/**
 * affirm分期
 * props: variant
 */
const Paypal = ({ price, className }) => {
  const { locale } = useRouter()

  return (
    <div
      className={className}
      data-pp-message
      data-pp-buyercountry={languageCode(locale)}
      data-pp-amount={price}
    ></div>
  )
}

export default Paypal
