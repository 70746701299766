import { parse, stringify } from 'query-string'

export const updateRef = (ref) => {
  const query = parse(window.location.search)
  query.ref = ref
  window.history.replaceState(
    {},
    '',
    `${window.location.pathname}?${stringify(query)}${window.location.hash}`
  )
  if (window.fbq) {
    fbq('trackCustom', 'Membership Signup')
  }
}
