import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { normalizeCart } from 'lib/utils/normalize'
import fetchGraphqlApi from 'lib/config/fetch-graphql-api'
import { cartAttributesUpdateMutation } from 'lib/shopify/mutations/cart'
import { Cart as CartType } from 'lib/shopify/types'
import { SHOPIFY_CHECKOUT_ID_COOKIE } from 'lib/config/const'

// todo!
import { ValidationError } from '@commerce/utils/errors'

import { useCart } from './use-cart'

export async function cartAttributesUpdateFn(
  locale: string = 'us',
  attributes: any,
  id?: string
): Promise<CartType.ExportCart> {
  // 活动折扣 cookie 全站生效，后端拿不到，所以暂时注释
  // const personaCookie = getPersonaCookie()
  const checkoutIdCookie = SHOPIFY_CHECKOUT_ID_COOKIE[locale]
  const cartId = id || Cookies.get(checkoutIdCookie)

  if (!cartId) {
    throw new ValidationError({
      message: 'Invalid input used for this operation: Miss cartId',
    })
  }
  const { res, status } =
    await fetchGraphqlApi<CartType.CartAttributesUpdateOperation>({
      locale,
      query: cartAttributesUpdateMutation,
      variables: {
        cartId,
        attributes,
      },
      cache: 'no-store',
    })

  const cart = res?.cartAttributesUpdate?.cart

  return cart && normalizeCart(cart)
}

export function useCartAttributesUpdate(id?: string) {
  const { locale } = useRouter()

  const { mutate, data } = useCart()
  const cartAttributesUpdate = useCallback(
    async (input: { customAttributes: any }) => {
      const attributes = input?.customAttributes
      if (!data) {
        throw new ValidationError({
          message: 'Invalid input used for this operation: Miss Cart Data',
        })
      }

      const result = await cartAttributesUpdateFn(locale, attributes, id)
      await mutate(result, false)
      return result
    },
    [data, locale, id, mutate]
  )

  return cartAttributesUpdate
}
